/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import Spinner from '../../components/spinner'
import { useGetListingQuery } from '../api'
import { useGetListingLotSkusQuery } from '../api/BuyerApi'
import MUIDataTable from '../MuiDatatableUtils'

/*
 *
 * Page
 *
 */

const Page = (props) => {
  // props
  const { listingId } = props

  // state
  const [selectedLotId, setSelectedLotId] = React.useState('all')

  // query
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const { isLoading = false, data: { listing_lots: listingLots = [] } = {} } = listingQuery
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: selectedLotId, listingLots, isVendor: false }) // N.B. from this page, we are always the buyer!
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot

  return <>
        <div className="card-body rounded-soft bg-white">
          <div className="pb-3">
              <Button size="sm" className={`m-1 ${selectedLotId === 'all' ? 'active' : ''}`} variant="outline-primary" onClick={() => setSelectedLotId('all')}>All</Button>
            {isLoading
              ? <Spinner />
              : listingLots.map(lot =>
              <Button key={lot.id} size="sm" className={`m-1 ${lot.id === selectedLotId ? 'active' : ''}`} variant="outline-primary" onClick={() => setSelectedLotId(lot.id)}>Lot {lot.lot_number}</Button>
              )
              }
          </div>
          <hr style={{ marginTop: '0px', marginLeft: '-20px', marginRight: '-20px' }}/>
          { listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
          { listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
          { listingLotSkusQuery.isSuccess && <MUIDataTable
                    columns={[
                      { label: 'Lot', name: 'listing_lot_number' },
                      { label: 'Make', name: 'make' },
                      { label: 'Model', name: 'model' },
                      { label: 'Memory', name: 'memory' },
                      { label: 'Grade', name: 'grade' },
                      { label: 'Device Type', name: 'device_type' },
                      { label: 'Colour', name: 'colour' },
                      { label: 'Kit', name: 'kit' },
                      { label: 'Carrier', name: 'carrier' },
                      { label: 'SIM Lock', name: 'sim_lock', options: { customBodyRender: (value) => (value + '') } },
                      { label: 'ID Lock', name: 'id_lock', options: { customBodyRender: (value) => (value + '') } },
                      { label: 'Region Lock', name: 'region_lock', options: { customBodyRender: (value) => (value + '') } },
                      { label: 'Quantity', name: 'qty' }
                    ]}
                    data={listingLotSkus}
                    options={{
                      pagination: true,
                      jumpToPage: false,
                      print: false,
                      search: true,
                      download: true,
                      sort: true,
                      filter: true,
                      viewColumns: true,
                      selectableRows: 'none',
                      elevation: 0,
                      rowsPerPage: 100,
                      setTableProps: () => ({ size: 'small' })
                    }}
                  />
            }

          { process.env.NODE_ENV !== 'production' && <pre>listingLotSkusQuery ={JSON.stringify(listingLotSkusQuery.data, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingQuery ={JSON.stringify(listingQuery.data, null, 2)}</pre>}

        </div>
      </>
}

export default Page
